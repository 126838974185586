* {
    margin: 0;
    letter-spacing: .1px;
    /* outline: 1px solid red; */
    color: #4b7645;
    font-family: 'Federo', sans-serif;
}

body {
    box-sizing: border-box;
}


/* TEST CAROUSEL   6/23 */
.carousel-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: whitesmoke;
    /* outline: 1px solid red; */
}

.carousel {
    overflow: hidden;
    width: 70vw;
    flex-direction: column;
    justify-content: center;
 
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
    /* box-shadow: 0 50px 15px rgba(0,0,0,0.9); */
}

.carousel-img {
    width: 40vw;
    height: 50vh;
    padding: 0 20px 0 20px;
}



.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60vh;   
    outline: 1px solie blue;
}

.carousel-item-description {
    font-size: 1.15rem;
    margin: 60px 0 60px 0;
    padding: 0 20px 0 20px;
    white-space: normal;
}

.carousel-buttons {
    display: flex;
    justify-content: space-evenly;

}

.button-arrow {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.indicators {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.indicator-buttons {
    border: none;
    cursor: pointer;
    padding: 5px;
}






/* Navbar Section */

.nav-container {
    height: 75px;
    width: 100%;
    background-color: rgba(75,118,69,1);
    -webkit-box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.9); 
    box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.9);
}

.nav-items {
    display: flex;
    height: 75px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.home-container {
    background-image: url("./images/ranch.png");
    min-height: 750px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 155px;
    gap: 25px;
    background-repeat: no-repeat;
    background-size: 105%;
}

.profile-pic {
    height: 135px;
    width: 135px;
    border-radius: 50%;
    border: 1px solid rgba(75,118,69,1);
}

#aboutMe-pic {
    height: 300px;
    width: 300px;
    background-image: url("./images/zakKids.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 25%;
    border-radius: 50%;
    border: 1px solid rgba(75,118,69,1);
    align-self: flex-start;
    left: 100px;
    position:relative;
    bottom: 75px;
}


.home-tags {
    font-size: 32px;
}

#name {
    font-size: 36px;
}

.nav-li {
    list-style-type: none;
    color: white;
    font-size: 24px;
}

.nav-title {
    font-family: 'Karla', sans-serif;
    font-size: 30px;
    margin-left: 100px;
}

.links-container {
    font-family: 'Karla', sans-serif;
    font-size: 15px;
    margin-right: 100px;
}

.link {
    margin-right: 15px;
    text-decoration: none;
    color: black;
}

#projects-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    gap:100px;
    margin-top: 50px;

}

.projects-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    max-width: 1200px;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;

}

.project-card {
    height: 350px;
    width: 550px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20%;    
    border: 1px solid black;
}




#project-details0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    border: none;
}
#project-details1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    border: none;
}
#project-details2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    border: none;
}

#test-img {
    height: 300px;
    width: 500px;
    border: 1px solid rgba(75,118,69,1);
    border-radius:  7px;
}

#projects-test-container {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-top: 50px;
    border-bottom: 5px solid rgba(75,118,69,1);
    border-top: 5px solid rgba(75,118,69,1);
     background-color: whitesmoke;
}

#campfire-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap:20px;
    align-items: center;
}

.skills-container {
    width: 100%;
    display: flex;
    font-size: 16px;
    }

.arrow {
    height: 300px;
    width: 100px;
    /* border: 1px solid black; */
    /* background-color: rgba(75,118,69,1); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: .65;
    cursor: pointer;
}

#img-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

}

.left-arrow {
    height: 30px;
    width: 30px;
    transform: rotate(45deg);
}
.right-arrow {
    height: 30px;
    width: 30px;
    transform: rotate(-135deg)
}

.project-description-div{
    display: flex;
    
}

#zakKids{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: 2px solid rgba(75,118,69,1);
}

#aboutMe-container {
    background-image: url("./images/ranch2.jpg");
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position:  50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 70px;
    padding-top: 50px;
}

.bio-list {
    display: flex;
   gap: 25px;
   font-size: 18px;
   position: relative;
   left: 50px;
   
}

.bio-container {
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 25px; 
    font-size: 24px;
    margin-left: 150px;
   position: relative;
   bottom: 40%;
}

.zakKids {
    height: 450px;
    width: 450px;
    border-radius: 50%;
}

.bullet {
    position: relative; 
    right: 12px;
    font-size: 14px;
}

/* Biography Section */

.bio-wrapper {
    height: 1000px;
    background-image: linear-gradient(180deg, #272935, #323442) ;
    display: grid;
    grid-template-columns: 40% 59%;
    grid-template-rows: repeat(2, 67%);

}

.profile-wrapper {
    font-family: 'Karla', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    gap: 20px;
    color: #f8f8f2;
    margin-left: 5%;
    margin-top: 100px;
}

.profile-img {
    height: auto;
    width: 400px;
    border-radius: 50%;
}

.profile-name {
    font-size: 36px;
}

.profile-description {
    color: #98a6ad;
}

.icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.icon {
    height: 75px;
    width: 75px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(40%) sepia(15%) saturate(1233%) hue-rotate(66deg) brightness(95%) contrast(83%);
}


   .github {
        background-image: url("./images/32-github.png");
    }

    .email {
        background-image: url("./images/32-email.png");
    }

    .linkedIn {
        background-image: url("./images/32-linkedin.png");
    }




.biography-container {
    font-family: 'Karla', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 150px;
    gap: 20px;
    color: #98a6ad;
}

.biography-title {
    font-size: 45px;
    color: #98a6ad;
}

.biography {
    color: #f8f8f2;
    font-size: 1rem;
    line-height: 2;
    width: 75%;
}

.skills-container {
    grid-column: 2/-1;
    display: flex;
    flex-direction: row;
    width: 75%;
    align-items: flex-start;
    font-family: 'Karla', sans-serif;
    gap: 150px;
}

.skills-title {
    color: #98a6ad;
    font-size: 25px;
}


.skills-list > li {
    margin-top:10px;
    color: #f8f8f2;
}

/* Project  */

/* .project-wrapper {
    /* background-color: #272935; */
    /* display: grid;
    grid-template-columns: 40% 35% 20%; */


.projects-header {
   grid-column: 1/2;
    text-align: center;
    margin-top: 100px;
    color: #98a6ad;
    font-size: 45px;
    font-family: 'Karla', sans-serif;
}

.project-img {
    width: 250px;
    height: auto;
    grid-column: 3/4;
    margin-top: 100px;
}

/* .project-details {
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    margin-top: 100px;
    gap: 5px;
    width: 75%;
    color: #f8f8f2;
    font-family: 'Karla', sans-serif;
} */

/* .github-btn-container {
     border: 1px solid #795548;
     border-radius: 10px;
}

.github-logo {
    background-image: url("./32-github.png");
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    
}

.code-btn {
    width: 50px;
    margin-top: 10px;
    background-color: #272935;
    color: #795548;
    padding: 5px;
    text-align: center;
    text-decoration: none;
} */

.name {
    margin-top: 25px;
    color: #98a6ad;
}

.project-description {
    font-weight: 100;
    font-size: 14px;
}

.line-break {
    grid-column: 2/-1;
    color: #98a6ad;
    height: 2px;
    background-color: #98a6ad;
    margin-top: 45px;
    width: 90%;
    margin-bottom: 25px;
}

/* Footer section */

.footer-wrapper {
    height: 100px;
    width: 100%;
    background-color:  rgba(75,118,69,1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.footer-header {
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
}




/* Carousel */

.project-carousel-container {
    background-color: #272935;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: 525px;
    padding-bottom: 50px;
    padding-left: 50px;
}

@media only screen and (max-width: 1400px) {

    /* .biography-title {
        font-size: 35px;
    }

    .biography {
        font-size: 15px;
    }

    .skills-title {
        font-size: 25px;
    }

    .skills-list {
        font-size: 12px;
    }

    .project-details {
        bottom: 90%;
    }


    .github-btn-container {
        width: 14%;
    }

    .project-carousel-container {
        height: 445px;
    }

    .carouselInner {
        height: 74%;
    } */

}

/* @media only screen and (max-width: 1275px), (max-height: 950px) {

    .profile-img {
        width: 60%;
        margin-bottom: 25px;
    }

    .skills-container {
        grid-column: 1/-1;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 50px;
}

    .bio-wrapper {
        height: 1000px;
    }

    .profile-wrapper {
     justify-content: flex-start;
     margin-top: 10px;
    }

    .github-btn-container {
        width: 14%;
    } */

    /* .email {
        background-image: url("./32-email.png");
    }

    .github {
        background-image: url("./32-github.png")
    }

    .linkedIn {
        background-image: url("./32-linkedin.png");
    } */

    /* .icon {
        height: 50px;
        width: 50px
 }

    .icon-container {
        gap: 2px;
    } */


@media only screen and (max-width: 950px) {

    /* .github {
        background-image: url("./32-github.png");
    }

    .email {
        background-image: url("./32-email.png");
    }

    .linkedIn {
        background-image: url("./32-linkedin.png");
    } */

    /* .icon {
        width: 34px;
    }

    .icon-container {
        gap: 10px;
    }

    .biography {
        font-size: 11px;
    }

    .bio-wrapper {
        height: 867px;
    } */
}

@media only screen and (max-width: 800px) {

    /* .top-link {
        left: 0;
    }

    .biography-container {
        margin-left: 20px;
    } */

}

@media only screen and (max-width: 620px) {

    /* .biography {
        font-size: 9px;
    }

    .biography-title {
        font-size: 30px;
    }

    .bio-wrapper {
        grid-template-rows: repeat(2, 58%);
    }

    .skills-title {
        font-size: 20px;
    }

    .skills-list {
        font-size: 9px;
    }

    .biography-container {
        margin-left: 40px;
    } */

}

@media only screen and (max-width: 550px) {

    /* .bio-wrapper {
        grid-template-rows: repeat(2, 65%);
    }

    .skills-container {
        gap: 75px;
    } */


}

@media only screen and (max-width: 500px) {

    /* .nav-title {
        margin-left: 65px;
    }

    .top-link {
        left: 0;
    }

    .bio-wrapper {
        grid-template-rows: 73% 36%;
        height: 100%;
    }

    .footer-header {
        font-size: 16px;
    }

    .footer-wrapper {
        height: 57px;
    }

    .top-link {
        height: 20px;
    }

    .nav-title {
        margin-left: 18px;
    }

    .links-container {
        margin-right: 8px;
    } */

}


@media only screen and (max-width: 420px) {

    #name {
        font-size: 16px;
    }

    .nav-li {
        font-size: 14px;
    }

    .nav-items {
        padding-left: 0px;
    }

  .profile-pic {
    height: 200px;
    width: 200px;
    position: relative;
    top: -100px;
    border: 2px solid;
  }
    
  .home-tags {
    position: relative;
    font-size: 24px;
  }


  .icon-container {
    position: relative;
    border-bottom: dashed rgba(75,118,69,1);
    padding-bottom: 25px;
  }

  .home-container {
    min-height: 565px;
  }

  #img-container {
  height: 200px;
  width: 350px;
  gap: 2px;
  }

  #test-img {
    height: 200px;
    width: 300px;
  }

  .arrow {
    width: 22px;
    height: 200px;
  }

  .project-description-div {
    font-size: 8px;
    height: 100px;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 2px dashed rgba(75,118,69,1);
    padding-bottom: 25px;
  }

  .bullet {
    position: relative;
    right: 6px;
  }

  

.skills-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: 'Karla', sans-serif;
    gap: 10px;

  
}

#projects-test-container {
    gap: 50px;
    padding-top: 25px;
    height: 100vh;
}

.skill-div> li {
    font-size: 14px;
   
}

.skill-div {
    display: flex;
    height: 25px;
    width: 250px;
    gap: 25px;
    flex-wrap: wrap;
  
}

.bio-list {
    font-size: 12px;
    left: 0px;
    flex-wrap: wrap;
    justify-content: center;
}

.bio-container {
    font-size: 12px;
    width: 350px;
    /* align-items: center;
    justify-content: center; */
    margin-left: 0px;
    bottom: 32%;
}

#aboutMe-pic {
    top: 300px;
    height: 200px;
    width: 200px;
}
    /* .profile-name {
        font-size: 28px;
    }

    .profile-description {
        font-size: 14px;
    }

    .biography {
        font-size: 7px;
    }

    .bio-wrapper {
        grid-template-rows: 70% 55%;
    }

    .skills-container {
        gap: 40px;
    }

    .skills-title {
        font-size: 18px;
    }

    .bio-wrapper {
        grid-template-columns: 40% 50%;
    } */

}


@media only screen and (max-height: 675px) {
    #projects-test-container {
        gap: 15px;
    }

    #aboutMe-container {
        gap: 20px;
        padding-top: 15px;
    }

    #aboutMe-container  > h1{
font-size: 26px;
    }

    .bio-container {
        width: 300px;
        padding-left: 45px;
        gap: 15px;
        
    }

    .bio-list {
        font-size: 9px;
    }

}



@media only screen and (min-height: 675px) {
    #projects-test-container {
        gap: 20px;
    }
}

@media only screen and (min-height: 800px) {
    #projects-test-container {
        gap: 40px;
    }


}




@media only screen and (max-width: 375px) {

    .carousel {
        width: 95vw;
    }

    .carousel-item {
        flex-direction: column;
        height: 70vh;
        justify-content: space-around;
    }

    .carousel-img {
        width: 90vw;
        height: 20vh;
        padding: 0;
    }

    .carousel-item-description {
        font-size: 1rem;
        margin: 30px 0 30px 0;
    }

    .carousel-wrapper {
        justify-content: space-between;
    }
    /* .profile-name {
        font-size: 22px;
    }

    .profile-wrapper {
        gap: 11px;
        padding-top: 97px;
    }

    .profile-img {
        margin-bottom: 0px;
    }

    .biography {
        font-size: 6px;
        width: 83%;
    }

.skills-container {
    margin-top: 5px;
}

    .skills-title {
        font-size: 14px;
    }

    .skills-list {
        font-size: 8px;
    }

    .bio-wrapper {
        grid-template-rows: 74% 25%;
        height: 592px;
    }

    .biography-container {
        padding-top: 107px;
        margin-left: 25px;
    }

    .biography-title {
        font-size: 25px;
    }

    .footer-wrapper {
        height: 55px;
    }

    .footer-header {
        font-size: 16px;
    }

    .top-link {
        left: 2em;
        height: 35px;
        width: 35px;
    }

    .nav-title {
        font-size: 22px;
        margin-left: 27px;
    }

    .links-container {
        margin-right: 15px;
    } */

}


